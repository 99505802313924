:root {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  margin: 0;
  cursor: pointer;
}
